import { Outlet } from "react-router-dom";
import { useState } from "react";
//styling
import './Admin.css'
//components
import SideBar from "../reusableComps/SideBar/SideBar.js";
//icons used in the side menu bar//
import dashBoardIcon from '../icons/dashBoardicon.svg';
import studentsIcon from '../icons/studentsicon.svg';
import teachersIcon from '../icons/teachersicon.svg';
import eventsIcon from '../icons/eventsicon.svg';
import settingsIcon from '../icons/settingsicon.svg';
import logouticon from '../icons/logouticon.svg';

function Admin() {
    const [menu, setMenu] = useState([
        { name: 'Logo', link: '', icon: '', status: false },
        { name: 'Dashboard', link: '/Admin', icon: dashBoardIcon, status: true },
        { name: 'Students', link: 'Students', icon: studentsIcon, status: false },
        { name: 'Teachers', link: 'Teachers', icon: teachersIcon, status: false },
        { name: 'Events', link: 'Events', icon: eventsIcon, status: false },
        { name: 'Settings', link: 'Settings', icon: settingsIcon, status: false },
        { name: 'Logout', link: '/', icon: logouticon, status: false }
    ]);

    const handleMenu = (optionName) => {

        const newMenuValue = [...menu];
        newMenuValue.forEach((option) => {
            if (option['name'] === optionName) option['status'] = true;
        });
        setMenu([...newMenuValue]);
    }
    return (
        <div className="Admin">
            <SideBar handleMenu={handleMenu} menu={menu} />
            <Outlet />
        </div>
    );
}

export default Admin;