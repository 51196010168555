import './CalendarGroup.css'


function CalendarGroup({ group
    , handleChangeGroup }) {

    return (
        <div className={`calendar-group-container`} data-testid={`calendar-group`}>
            <div>
                <h3>Events</h3>
                <div className={`calendar-types`} >
                    <div className={`calendar-type`}>
                        <div
                            className={`custom-radio ${group ==='personal' ? 'selected' : ''}`}
                            onClick={() => handleChangeGroup('personal') }  data-testid={`calendarGroup-personalBtn`} 
                        ></div>
                        <label htmlFor="personal">Personal</label>
                    </div>

                    <div className={`calendar-type`}>
                        <div
                            className={`custom-radio ${group ==='shared' ? 'selected' : ''}`}
                            onClick={() => handleChangeGroup('shared')}  data-testid={`calendarGroup-sharedBtn`}
                        ></div>
                        <label htmlFor="shared">Shared</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CalendarGroup