function isUpperCase(letter) {
    return letter === letter.toUpperCase();
}
export function fieldNameFormat(keyName) {
    const letterArr = keyName.split('');
    const spaceIndexList = [];

    letterArr.forEach((letter, i) => {
        if (isUpperCase(letter)) spaceIndexList.push(i);
    });
    letterArr[0] = letterArr[0].toUpperCase();
    for (let i = 0; i < spaceIndexList.length; i++) {
        letterArr.splice(spaceIndexList[i], 0, ' ');
        if (i + 1 < spaceIndexList.length) spaceIndexList[i + 1] = spaceIndexList[i + 1] + 1;
    }

    return letterArr.join('');
}