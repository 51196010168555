import React, { useState } from 'react'
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import 'primereact/resources/primereact.min.css';
import './CalendarEvents.css'


function CalendarEvents() {
  const [selectedDate, setSelectedDate] = useState(new Date().getDate());
  const [showFullCalendar, setShowFullCalendar] = useState(false);
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const events = [
    { title: "PTA Meeting in two days", time: "Set Reminder" },
    { title: "General Staff Meeting", time: "2:00pm" },
    { title: "End of year Committee", time: "3:00pm" },
    { title: "Exams and records", time: "4:00pm" },
    { title: "End of year Committee", time: "3:00pm" },
    { title: "Exams and records", time: "4:00pm" },
    { title: "PTA Meeting in two days", time: "Set Reminder" },
    { title: "General Staff Meeting", time: "2:00pm" },
    { title: "End of year Committee", time: "3:00pm" },
    { title: "Exams and records", time: "4:00pm" },
    { title: "End of year Committee", time: "3:00pm" },
    { title: "Exams and records", time: "4:00pm" },
  ];

  return (
    <Card className='calendar-container'>

      {/* header */}
      <div className='calendar-title'>
        <h5>
          {`Today ${new Date().getDate()} ${new Date().toLocaleDateString('default', { month: 'long' })}`}
        </h5>
        <button
          // icon="pi pi-calendar" 
          className="calendar-icon"
          onClick={() => setShowFullCalendar(!showFullCalendar)}
        />
        {showFullCalendar && <Calendar inline className='calendar-full' />}
      </div>

      {/* days */}
      <div className='date-buttons'>
        {
          Array.from({ length: 5 }, (day, index) => (
            <button
              key={index}
              className={selectedDate === (new Date().getDate() + index - 2) ? 'date-clicked' : 'date-non-clicked'}
              onClick={() => setSelectedDate(new Date().getDate() + index - 2)}
            >
              <div className='date-days-container'>
                <div>
                  {new Date().getDate() + index - 2}
                </div>
                <div>
                  {weekdays[(new Date().getDay() + index - 2 + 7) % 7]}
                </div>
              </div>
            </button>

          ))}

      </div>

      {/* Events */}
      <div className='events-container'>
        {events.map((event, index) => (
          <div key={index} className='event-container'>
            <span className='event-title'>{event.title}</span>
            <span className='event-time'>{event.time}</span>
          </div>
        ))}
      </div>


    </Card>
  )
}

export default CalendarEvents