import { useState, useCallback } from "react";
import { StudentContext } from "./context/studentContext";
import { Header, StudentList, AdminStudentDetails, AdminStudentAddExport } from '../../Admin/AdminImports';
import './AdminStudentSec.css';
import { data } from "../Dashboard/dummyData";


function Students() {
    const [studentSelected, setStudentSelected] = useState(data[0]);
    const handleStudentSelected = (first_Name, last_Name) => {
        let newStudentSelected = {};

        data.forEach(({ firstName, lastName }, i) => {

            if (firstName === first_Name && lastName === last_Name) {

                newStudentSelected = data[i];
            }
        })
        setStudentSelected({ ...newStudentSelected })
    };
    const [popUpEditAdd, setPopUpEditAdd] = useState([
        { component: 'adminStudentAddExport', name: 'Create', status: false },
        { component: 'BasicDetails', name: 'Update', status: false },
        { component: 'BasicDetails', name: 'Delete', status: false },
        { component: 'adminStudentAddExport', name: 'Export', status: false }
    ]);
    const handlePopUpEditAdd = (component, name) => {
        const newPopUps = [...popUpEditAdd];
        newPopUps.forEach((elem) => {
            if (elem['component'] === component && elem['name'] === name) elem['status'] = !elem['status'];
            else elem['status'] = false;
        });
        setPopUpEditAdd([...newPopUps])
    };
    const [toExportVal, setToExportVal] = useState([]);
    const handleToExportVal = useCallback((checkedItems) => setToExportVal([...checkedItems]), []);

    return (
        <div data-testid="Students" className="Students">
            <Header menuName='Students' />
            <StudentContext.Provider value={{ data, handleStudentSelected, studentSelected, handleToExportVal, toExportVal, handlePopUpEditAdd }} >
                <div className="studentListData-container">
                    <div className="container-leftSide">
                        <AdminStudentAddExport
                            popUpEditAdd={popUpEditAdd}
                        />
                        <StudentList />
                    </div>
                    <AdminStudentDetails
                        student={studentSelected}
                        popUpEditAdd={popUpEditAdd}
                        handlePopUpEditAdd={handlePopUpEditAdd}
                    />
                </div>
            </StudentContext.Provider>
        </div>
    );
}

export default Students;