import { useContext, useRef, useState, useEffect, useCallback } from "react";

import {
    getMonthName, todayDate, yearsListInit, CalendarContext
} from "../../../Admin/AdminImports";

import leftArrow from "../../../icons/leftArrow.svg";
import rightArrow from "../../../icons/rightArrow.svg";
import arrowDown from "../../../icons/arrowDown.svg";
import todayIcon from "../../../icons/todayIcon.svg";
import monthIcon from "../../../icons/monthIcon.svg";
import arrowUp from "../../../icons/arrowUp.svg";

import './CalendarNavigation.css';
const MonthSelection = ({ handleSelection }) => {
    const { handleMonth } = useContext(CalendarContext);
    const monthList = ["Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"];
    const monthRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (monthRef.current && !monthRef.current.contains(event.target))
                handleSelection('');
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleSelection]);

    return <div className="monthList" ref={monthRef}>
        {
            monthList.map((elem, index) => <div
                data-testid={`monthList-${elem}`}
                key={elem}
                className="month"
                onClick={() => { handleMonth(0, index + 1); handleSelection() }}
            >{elem}</div>)
        }
    </div>;
}
const YearSelection = ({ handleSelection }) => {
    const { handleYear } = useContext(CalendarContext);
    const today = todayDate();

    const [yearList, setYearList] = useState(yearsListInit(today.year));

    const handleTimeFrame = (action) => {
        let newFrame = [];
        if (action === 'back')
            newFrame = yearsListInit(yearList[0]);
        else if (action === 'front') newFrame = yearsListInit(yearList[yearList.length - 1]);
        setYearList(newFrame);
    };
    const yearRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (yearRef.current && !yearRef.current.contains(event.target))
                handleSelection('');
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleSelection]);

    return <div className="yearSelection" ref={yearRef}>
        <div className="backFrontNav">
            <img
                data-testid={`yearSelection-backFrontNav-backwardArrow`}
                onClick={() => { handleTimeFrame('back') }}
                src={arrowUp} alt="arrow to navigate past years" ></img>
            <img
                data-testid={`yearSelection-backFrontNav-frontwardArrow`}
                onClick={() => { handleTimeFrame('front') }}
                src={arrowDown} alt="arrow to navigate future years"></img>
        </div>
        {
            yearList.map((year) => <div
                data-testid={`yearSelection-${year}`}
                className="option"
                key={year.toString()}
                onClick={() => { handleSelection(); handleYear(0, year) }}
            >{year}</div>)
        }
    </div>
}
function CalendarNavigation() {
    const [yearMonthBtn, setYearMonthBtn] = useState(false);
    const [yearMonthSelection, setYearMonthSelection] = useState({
        year: false,
        month: false
    });
    const { month, year, handleMonth, handleYear } = useContext(CalendarContext);

    const today = todayDate();
    const monthInLetters = getMonthName(month);

    const handleYearMonthBtn = () => setYearMonthBtn(!yearMonthBtn);
    const handleSelection = useCallback((action) => {
        if (action === 'year') setYearMonthSelection({ month: false, year: true });
        else if (action === 'month') setYearMonthSelection({ year: false, month: true });
        else setYearMonthSelection({ year: false, month: false });
    }, []);


    const menuRef = useRef(null);
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target))
                setYearMonthBtn(false);
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return <nav className='CalendarNavigation'>

        <div
            data-testid={`todayBtn`}
            className="todayBtn"
            onClick={() => { handleMonth(0, today.month + 1); handleYear(0, today.year); }}>
            <img src={todayIcon} alt="Today icon"></img>
            <span>Today</span>
        </div>

        <div className='monthNavigation'>
            <div className="innerDiv">
                <img src={leftArrow} alt="left arrow navigation" onClick={() => handleMonth(-1)} data-testid={`monthNavigation-leftArrow`}></img>
                <h1 className='Month' data-testid={`monthNavigation-currentMonthYear`}>{monthInLetters} {year}</h1>
                <img src={rightArrow} alt="right arrow navigation" onClick={() => handleMonth(1)} data-testid={`monthNavigation-rightArrow`}></img>
            </div>
        </div>

        <div className="yearMonthNav" >
            <img onClick={() => handleYearMonthBtn()} src={monthIcon} alt="month icon"></img>
            <div className="monthYearMenu">
                <span onClick={() => handleYearMonthBtn()} data-testid={`yearMonthNav-navYearOrMonth-monthYearMenu`}
                >Month - Year</span>
                {yearMonthBtn ?
                    <nav className="navYearOrMonth" ref={menuRef}>
                        <div
                            data-testid={`yearMonthNav-navYearOrMonth-monthYearMenu-month`}
                            onClick={() => { handleSelection("month"); handleYearMonthBtn() }}
                            className="option"
                            style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>Month</div>
                        <div
                            data-testid={`yearMonthNav-navYearOrMonth-monthYearMenu-year`}
                            onClick={() => { handleSelection("year"); handleYearMonthBtn(); }}
                            className="option"
                            style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>Year</div>
                    </nav> : <></>}
            </div>
            <img onClick={() => handleYearMonthBtn()} src={yearMonthBtn ? arrowUp : arrowDown} alt="arrow down icon"></img>

            {
                yearMonthSelection.month && <MonthSelection handleSelection={handleSelection} />
            }
            {
                yearMonthSelection.year && <YearSelection handleSelection={handleSelection} />
            }
        </div>

    </nav>
}

export default CalendarNavigation;