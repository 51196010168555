import { useState } from 'react';
import PropTypes from 'prop-types';
import { PopUpEditAdd, PopUpDelete }  from '../../../../Admin/AdminImports'
import { penEditIcon, trashIcon } from '.././../../../assetsImports' 
import { fieldNameFormat } from '../../../useFullFunctions/useFullFunc';

const DetailBlock = ({ blockName, blockData, blockDataAdditional }) =>
    <div className="DetailBlock">
        <span className="blockName">{fieldNameFormat(blockName)}</span>
        <span className="blockData" data-testid={`BasicDetails-detailBlock-${blockName}`}>{blockData}</span>
        {
            blockDataAdditional.length > 0 ?
                blockDataAdditional.map((data, i) =>
                    <span
                        className="blockData"
                        key={i.toString()}
                        data-testid={`BasicDetails-detailBlock-${blockName} ${data['value']}`}
                    >{data['value']}
                    </span>
                ) :
                <></>
        }
    </div>;

const BasicDetails = ({
    student, title,
    popUpEditAdd, handlePopUpEditAdd }) => {

    const [studentUpdatePreview, setStudentUpdatePreview] = useState({ ...student });

    const handleSubmit = (e) => {
        e.preventDefault();
    };
    const handleFields = (fieldName, e) => {
        const updatePreview = { ...studentUpdatePreview };

        const subFieldsChange = fieldName.split('-');
        const parentField = subFieldsChange[1];
        const childField = subFieldsChange[0];

        if (subFieldsChange.length > 1) {
            updatePreview[parentField][childField] = e.target.value;
        } else {
            updatePreview[fieldName] = e.target.value;
        }
        setStudentUpdatePreview(updatePreview);
    };
    const handleDeletion = (studentId) => {
        console.log(studentId);
    };

    const studentkeysToAvoid = (elem) =>
        elem !== 'stu_id' && elem !== 'courses'
        && elem !== 'presence' && elem !== 'classCompletionStatus'
        && elem !== 'photo' && elem !== 'class';

    const studentDataKeys = Object.keys(student).filter((elem) => studentkeysToAvoid(elem));

    return (
        <div className="BasicDetails">
            <PopUpEditAdd
                data={studentUpdatePreview}
                handleSubmit={handleSubmit}
                handleFields={handleFields}
                name={'Update'}
                component={'BasicDetails'}
                popUpEditAdd={popUpEditAdd}
                handlePopUpEditAdd={handlePopUpEditAdd}
                
            />
            <PopUpDelete
                data={student}
                handleDeletion={handleDeletion}
                name={'Delete'}
                component={'BasicDetails'}
                popUpEditAdd={popUpEditAdd}
                handlePopUpEditAdd={handlePopUpEditAdd}
            />
            <div className='Title'>
                <h3>{title}</h3>
                <div className='edit-delete-container'>
                    <img onClick={() => handlePopUpEditAdd('BasicDetails', 'Update')} src={penEditIcon} alt='edit data icon' data-testid={'BasicDetails-PopUpEditbtn'}></img>
                    <img onClick={() => handlePopUpEditAdd('BasicDetails', 'Delete')} src={trashIcon} alt='delete student icon' data-testid={'BasicDetails-PopUpDeletebtn'}></img>
                </div>
            </div>
            <div className="studentDetails">
                {
                    studentDataKeys.map((key) => {
                        if (typeof student[key] !== 'object') {
                            if (key !== 'firstName' && key !== 'lastName'
                                && key !== 'address')
                                return <DetailBlock
                                    blockName={key}
                                    key={key}
                                    blockData={student[key]}
                                    blockDataAdditional={[]}
                                />;
                            if (key === 'firstName')
                                return <DetailBlock
                                    blockName={'name'}
                                    key={key}
                                    blockData={`${student[key]} ${student['lastName']}`}
                                    blockDataAdditional={[]}
                                />;
                            else return '';
                        } else return '';
                    }
                    )
                }

            </div>
            <div className="studentAdditionalDetails">
                {
                    studentDataKeys.map(
                        (key) => {
                            if (typeof student[key] === 'object')
                                return <DetailBlock
                                    key={key}
                                    blockName={key}
                                    blockData={`${student[key].name}`}
                                    blockDataAdditional={[{ name: 'phoneNum', value: student[key]['phoneNum'] }, { name: 'phoneNum', value: student[key]['email'] }]}
                                />;

                            if (key === 'address')
                                return <DetailBlock
                                    blockName={key}
                                    key={key}
                                    blockData={student[key]}
                                    blockDataAdditional={[]}
                                />;
                            else return '';
                        }
                    )
                }
            </div>
        </div >
    )
}

BasicDetails.propTypes = {
    student: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        religion: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        parentOne: PropTypes.shape({
            name: PropTypes.string.isRequired,
            phoneNum: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        }),
        parentTwo: PropTypes.shape({
            name: PropTypes.string.isRequired,
            phoneNum: PropTypes.string.isRequired,
            email: PropTypes.string.isRequired,
        })
    }).isRequired,
    // popUpEditAdd: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         component: PropTypes.string.isRequired,
    //         name: PropTypes.string.isRequired,
    //         status: PropTypes.bool.isRequired
    //     })
    // ).isRequired,
    // handlePopUpEditAdd: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
export default BasicDetails;