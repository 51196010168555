import React, { useState } from 'react'
import { closeIcon } from '../../../assetsImports'
import './Filter.css'



function Filter({ filterData, onFilterSelect, closeFilter }) {
    const uniqueClass = Array.from(new Set(filterData.map(item => item.class)));
    const uniqueGender = Array.from(new Set(filterData.map(item => item.gender)));
    const uniqueReligion = Array.from(new Set(filterData.map(item => item.religion)));
    const [selectedFilters, setSelectedFilters] = useState([]);

    const handleFilterClick = (e, value) => {
        e.stopPropagation();
        console.log(uniqueClass, uniqueCourse);
        if (selectedFilters.includes(value)) {
            setSelectedFilters(selectedFilters.filter(f => f !== value));
        } else {
            setSelectedFilters([...selectedFilters, value]);
        }
    }

    const uniqueCourse = new Set();
    filterData.forEach(item => {
        item.courses.forEach(course => {
            uniqueCourse.add(course.name);
        });
    });

    const courseElements = Array.from(uniqueCourse).map((course, index) => (
        <div className={` ${selectedFilters.includes(course) ? 'selected-item' : ''} filter-by-data`} key={index}>
        <button onClick={(e) => handleFilterClick(e, course)} className={`${selectedFilters.includes(course) ? 'selected-item' : ''}`}>
                {course}
            </button>
        </div>
    ));

    const classElements = uniqueClass.map((aClass, index) =>
        <div className={` ${selectedFilters.includes(aClass) ? 'selected-item' : ''} filter-by-data`} key={index}>
            <button onClick={(e) => handleFilterClick(e, aClass)} className={`${selectedFilters.includes(aClass) ? 'selected-item' : ''}`}>
                {aClass}
            </button>
        </div>
    )

    const genderElements = uniqueGender.map((gender, index) =>
        <div className={` ${selectedFilters.includes(gender) ? 'selected-item' : ''} filter-by-data`} key={index}>
            <button onClick={(e) => handleFilterClick(e, gender)} className={`${selectedFilters.includes(gender) ? 'selected-item' : ''}`}>
                {gender}
            </button>
        </div>
    )

    const religionElements = uniqueReligion.map((religion, index) =>
        <div className={` ${selectedFilters.includes(religion) ? 'selected-item' : ''} filter-by-data`} key={index}>
        <button onClick={(e) => handleFilterClick(e, religion)} className={`${selectedFilters.includes(religion) ? 'selected-item' : ''}`}>
                {religion}
            </button>
        </div>
    )
    const filterElements = [
        {
            title: "Class",
            element: classElements,
        },
        {
            title: "Gender",
            element: genderElements,
        },
        {
            title: "Religion",
            element: religionElements,
        },
        {
            title: "Course",
            element: courseElements,
        },
    ]

    return (
        <div className='filter-container'>
            <div className='filter-options'>
                <div className='close-filter-container'>
                    <img
                        src={closeIcon}
                        alt='close icon'
                        onClick={closeFilter}
                        data-testid='close-button'
                    ></img>
                </div>
                <div>
                    <p style={{ fontWeight: 800 }}>Filter By</p>
                </div>
                {
                    filterElements.map((item, key) =>
                        <div
                            key={key}
                        >
                            <p className='filter-titles'> {item.title} </p>
                            <div className='filterItem'> {item.element} </div>
                        </div>
                    )
                }

                <div className='done-container'>
                    <button
                        className='done-button'
                        onClick={() => onFilterSelect(selectedFilters)}
                    >
                        Done
                    </button>
                </div>
            </div>
        </div>

    )
}

export default Filter
