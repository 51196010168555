import { closeIcon, closeIconRed} from '../../../assetsImports'
import './PopUpDelete.css'

function PopUpDelete(
    {
        data,
        handleDeletion,
        name,
        component,
        popUpEditAdd,
        handlePopUpEditAdd
    }
) {
    const popUpView = popUpEditAdd.filter((popUp) => popUp['name'] === name && popUp['component'] === component)[0];

    return (popUpView['status'] ? <>
        <div
            style={popUpView['status'] ? { display: 'flex' } : { display: 'none' }}
            className="popUpDelete"
            data-testid={`popUpDelete-${component}`}
        >

            <img
                className='closeBtn'
                onClick={() => handlePopUpEditAdd(component, name)}
                data-testid={`popUpDelete-close-icon-${component}`}
                src={closeIcon} alt="closewindow icon"></img>
            <img
                className="dangerSign"
                src={closeIconRed} alt="deletion icon"></img>
            <h1>Are you sure?</h1>
            <p>Do you really want to delete the student? This process cannot be undone.</p>
            <div className="cancel-delete-container">
                <div className='cancelBtn'>Cancel</div>
                <div
                    className='deleteBtn'
                    onClick={() => handleDeletion(data['stu_id'])}
                >Delete</div>
            </div>

        </div>
    </> : <></>
    );
}

export default PopUpDelete;