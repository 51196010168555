import PropTypes from 'prop-types';
const UpperSec = ({ student }) => {
    return (
        <div className='UpperSec'>
            <img data-testid={`UpperSec-${student['firstName']}`} src={student['photo']} alt='studentImage'></img>
            <div className='shortDescript'>
                <span className='studentName' data-testid={`UpperSec-${student['firstName']}-${student['stu_id']}`}>{`${student['firstName']} ${student['lastName']}`}</span>
                <span className='class'>Class <span data-testid={`UpperSec-${student['class']}`}>{student['class']}</span></span>
            </div>
        </div>
    )
}
UpperSec.propTypes = {
    student: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        class: PropTypes.string.isRequired,
        photo: PropTypes.string,
    }).isRequired,
};
export default UpperSec;