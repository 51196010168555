export const staffList = [
    { name: 'David Ross', discipline: 'Chemistry', attendance: 95, students: 27 },
    { name: 'Alice Maddison', discipline: 'Physics', attendance: 100, students: 30 },
    { name: 'Michael Richard', discipline: 'Biochemistry', attendance: 90, students: 28 },
    { name: 'Rachel Green', discipline: 'Biology', attendance: 100, students: 33 },
    { name: 'Rachel Green', discipline: 'Biology', attendance: 100, students: 33 },
    { name: 'Alice Maddison', discipline: 'Physics', attendance: 100, students: 30 },
    { name: 'David Ross', discipline: 'Chemistry', attendance: 95, students: 27 },
    { name: 'Alice Maddison', discipline: 'Physics', attendance: 100, students: 30 },
    { name: 'Michael Richard', discipline: 'Biochemistry', attendance: 90, students: 28 },
    { name: 'Michael Richard', discipline: 'Biochemistry', attendance: 90, students: 28 },
    { name: 'Rachel Green', discipline: 'Biology', attendance: 100, students: 33 },
    { name: 'Rachel Green', discipline: 'Biology', attendance: 100, students: 33 }

];
export const columnNames = [
    'Name', 'Discipline', 'Attendance', 'Students'
]


export const data =
    [{
        photo: "https://robohash.org/laborumperferendissed.png?size=50x50&set=set1",
        stu_id: "01HZC11",
        lastName: 'Berge',
        firstName: 'Trisha', 
        dateOfBirth: '2004-04-29',
        religion: 'Christian',
        gender: 'Female', 
        class: '10A',
        classCompletionStatus: true,
        address: 'Av.du Large kinindo 99 Bujumbura Burundi',
        parentOne: {
            name: 'Richard Berge',
            phoneNum: "+1603-965-4668",
            email: 'richardBerge@gmail.com'
        },
        parentTwo: { name: 'Nadine Berge', phoneNum: "+1603-965-4668", email: 'nadineBerge@gmail.com' },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 20, test: 16, date: "2023/09/10" },
                    { maxGrade: 30, test: 12, date: "2023/09/20" },
                    { maxGrade: 20, test: 13, date: "2023/10/10" },
                    { maxGrade: 20, test: 18, date: "2023/10/20" },
                    { maxGrade: 20, test: 11, date: "2023/11/10" },
                    { maxGrade: 20, test: 10, date: "2023/11/20" },
                    { maxGrade: 50, coursework: 20, date: "2023/10/10" },
                    { maxGrade: 30, exam: 15, date: "2023/11/28" },
                    { maxGrade: 30, exam: 25, date: "2023/11/30" }
                ]
            },
            {
                name: 'Geography',
                grades: [

                    { maxGrade: 20, test: 0, date: "2023/09/10" },
                    { maxGrade: 20, test: 8, date: "2023/09/30" },
                    { maxGrade: 20, test: 13, date: "2023/10/8" },
                    { maxGrade: 20, test: 10, date: "2023/10/26" },
                    { maxGrade: 20, test: 15, date: "2023/11/7" },
                    { maxGrade: 20, test: 0, date: "2023/11/20" },
                    { maxGrade: 20, test: 14, date: "2023/11/28" },
                    { maxGrade: 10, coursework: 8, date: "2023/10/11" },
                    { maxGrade: 20, exam: 5, date: "2023/11/29" },
                    { maxGrade: 20, exam: 15, date: "2023/11/30" }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 20, test: 12, date: "2023/09/15" },
                    { maxGrade: 20, test: 11, date: "2023/09/30" },
                    { maxGrade: 20, test: 13, date: "2023/10/8" },
                    { maxGrade: 20, test: 20, date: "2023/10/26" },
                    { maxGrade: 20, test: 18, date: "2023/11/7" },
                    { maxGrade: 20, test: 14, date: "2023/11/28" },
                    { maxGrade: 5, coursework: 1, date: "2023/10/01" },
                    { maxGrade: 20, exam: 20, date: "2023/11/29" },
                    { maxGrade: 20, exam: 15, date: "2023/11/30" }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 20, test: 5, date: "2023/09/3" },
                    { maxGrade: 20, test: 12, date: "2023/09/6" },
                    { maxGrade: 20, test: 15, date: "2023/10/8" },
                    { maxGrade: 20, test: 11, date: "2023/10/30" },
                    { maxGrade: 20, test: 11, date: "2023/11/2" },
                    { maxGrade: 20, test: 10, date: "2023/11/24" },
                    { maxGrade: 5, coursework: 1, date: "2023/10/3" },
                    { maxGrade: 20, exam: 20, date: "2023/11/12" },
                    { maxGrade: 20, exam: 15, date: "2023/11/7" }
                ]
            },
            {
                name: 'Physics',
                grades: [
                    { maxGrade: 20, test: 11, date: "2023/09/1" },
                    { maxGrade: 20, test: 11, date: "2023/09/4" },
                    { maxGrade: 20, test: 20, date: "2023/10/27" },
                    { maxGrade: 20, test: 20, date: "2023/10/29" },
                    { maxGrade: 20, test: 15, date: "2023/11/5" },
                    { maxGrade: 20, test: 12, date: "2023/11/17" },
                    { maxGrade: 20, coursework: 18, date: "2023/10/18" },
                    { maxGrade: 20, exam: 20, date: "2023/11/23" },
                    { maxGrade: 20, exam: 20, date: "2023/11/26" }
                ]
            },
            {
                name: 'Biology',
                grades: [
                    { maxGrade: 20, test: 20, date: "2023/09/1" },
                    { maxGrade: 20, test: 20, date: "2023/09/4" },
                    { maxGrade: 30, test: 30, date: "2023/10/27" },
                    { maxGrade: 20, test: 20, date: "2023/10/29" },
                    { maxGrade: 20, test: 20, date: "2023/11/5" },
                    { maxGrade: 20, test: 20, date: "2023/11/17" },
                    { maxGrade: 20, coursework: 18, date: "2023/10/18" },
                    { maxGrade: 20, exam: 20, date: "2023/11/23" },
                    { maxGrade: 20, exam: 20, date: "2023/11/26" }
                ]
            }
        ],

    },
    {
        photo: "https://robohash.org/laborumperferendissed.png?size=50x50&set=set1",
        stu_id: "01HZC74",
        lastName: "Carrel",
        firstName: "Hayes",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Male",

        class: "10B",
        classCompletionStatus: true,
        address: "45593 Dakota Trail",
        parentOne: {
            name: "Hayes Carrel",
            phoneNum: "895-415-5349",
            email: "hcarrel0@indiegogo.com"
        },
        parentTwo: {
            name: "Hayes Carrel",
            phoneNum: "311-612-3413",
            email: "hcarrel0@imageshack.us"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/autcupiditateet.png?size=50x50&set=set1",
        stu_id: "01HZCN4",
        lastName: "O'Hanley",
        firstName: "Kaela",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Female",

        class: "10B",
        classCompletionStatus: false,
        address: "30 Westport Hill",
        parentOne: {
            name: "Kaela O'Hanley",
            phoneNum: "659-878-5573",
            email: "kohanley1@wunderground.com"
        },
        parentTwo: {
            name: "Kaela O'Hanley",
            phoneNum: "318-149-7049",
            email: "kohanley1@squidoo.com"
        },
        presence: [
            { date: "2023/09/05", presence: false },
            { date: "2023/09/16", presence: true },
            { date: "2023/09/09", presence: true },
            { date: "2023/09/01", presence: false },
            { date: "2023/09/15", presence: false },
            { date: "2023/09/05", presence: false },
            { date: "2023/09/14", presence: true },
            { date: "2023/09/06", presence: true },
            { date: "2023/09/01", presence: true },
            { date: "2023/09/01", presence: true },
            { date: "2023/09/01", presence: true },
            { date: "2023/09/16", presence: false },
            { date: "2023/09/14", presence: true },
            { date: "2023/09/03", presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/temporequasimagni.png?size=50x50&set=set1",
        stu_id: "01HZCRQ",
        lastName: "Larret",
        firstName: "Jermaine",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Male",

        class: "10C",
        classCompletionStatus: false,
        address: "3573 Schiller Place",
        parentOne: {
            name: "Jermaine Larret",
            phoneNum: "754-717-8758",
            email: "jlarret2@mysql.com"
        },
        parentTwo: {
            name: "Jermaine Larret",
            phoneNum: "454-718-2830",
            email: "jlarret2@163.com"
        },
        presence: [
            { date: "2023/09/06", presence: true },
            { date: "2023/09/07", presence: false },
            { date: "2023/09/03", presence: false },
            { date: "2023/09/01", presence: true },
            { date: "2023/09/01", presence: false },
            { date: "2023/09/04", presence: true },
            { date: "2023/09/19", presence: true }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/utestvero.png?size=50x50&set=set1",
        stu_id: "01HZC82",
        lastName: "Wallas",
        firstName: "Curtice",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Male",

        class: "10C",
        classCompletionStatus: false,
        address: "566 Thackeray Street",
        parentOne: {
            name: "Curtice Wallas",
            phoneNum: "582-179-1482",
            email: "cwallas3@mozilla.com"
        },
        parentTwo: {
            name: "Curtice Wallas",
            phoneNum: "998-776-2516",
            email: "cwallas3@ebay.com"
        },
        presence: [
            { date: "2023/09/19", presence: true },
            { date: "2023/09/18", presence: true },
            { date: "2023/09/11", presence: true },
            { date: "2023/09/01", presence: true },
            { date: "2023/09/17", presence: true },
            { date: "2023/09/13", presence: true },
            { date: "2023/09/16", presence: true },
            { date: "2023/09/03", presence: true },
            { date: "2023/09/10", presence: true },
            { date: "2023/09/05", presence: true },
            { date: "2023/09/05", presence: true },
            { date: "2023/09/20", presence: true },
            { date: "2023/09/05", presence: false },
            { date: "2023/09/09", presence: false },
            { date: "2023/09/09", presence: true },
            { date: "2023/09/02", presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/quidemteneturoptio.png?size=50x50&set=set1",
        stu_id: "01HZCKM",
        lastName: "Davenhall",
        firstName: "Ynes",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Female",

        class: "10B",
        classCompletionStatus: false,
        address: "60843 Dunning Point",
        parentOne: {
            name: "Ynes Davenhall",
            phoneNum: "807-479-6655",
            email: "ydavenhall4@businessinsider.com"
        },
        parentTwo: {
            name: "Ynes Davenhall",
            phoneNum: "188-674-6429",
            email: "ydavenhall4@icio.us"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/blanditiissimiliquenemo.png?size=50x50&set=set1",
        stu_id: "01HZXCP",
        lastName: "Clayhill",
        firstName: "Tabbatha",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Female",

        class: "10B",
        classCompletionStatus: false,
        address: "2789 Eagan Center",
        parentOne: {
            name: "Tabbatha Clayhill",
            phoneNum: "389-678-2027",
            email: "tclayhill5@tumblr.com"
        },
        parentTwo: {
            name: "Tabbatha Clayhill",
            phoneNum: "112-160-1435",
            email: "tclayhill5@apple.com"
        },
        presence: [
            { date: "2023/09/05", presence: false },
            { date: "2023/09/10", presence: false },
            { date: "2023/09/11", presence: true },
            { date: "2023/09/18", presence: true }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/eiusnecessitatibusfacilis.png?size=50x50&set=set1",
        stu_id: "01HZCXP",
        lastName: "McBryde",
        firstName: "Iosep",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Male",

        class: "11A",
        classCompletionStatus: false,
        address: "54836 Saint Paul Junction",
        parentOne: {
            name: "Iosep McBryde",
            phoneNum: "727-514-0274",
            email: "imcbryde6@slideshare.net"
        },
        parentTwo: {
            name: "Iosep McBryde",
            phoneNum: "723-354-3038",
            email: "imcbryde6@oakley.com"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/quasiutaut.png?size=50x50&set=set1",
        stu_id: "01HZCVC",
        lastName: "Bremond",
        firstName: "Murry",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Male",

        class: "11B",
        classCompletionStatus: true,
        address: "66141 Park Meadow Junction",
        parentOne: {
            name: "Murry Bremond",
            phoneNum: "832-787-1819",
            email: "mbremond7@google.ca"
        },
        parentTwo: {
            name: "Murry Bremond",
            phoneNum: "670-339-3856",
            email: "mbremond7@exblog.jp"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/veritatisvelsed.png?size=50x50&set=set1",
        stu_id: "01HZCFQ",
        lastName: "Uden",
        firstName: "Tobin",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Male",

        class: "11B",
        classCompletionStatus: false,
        address: "742 Bonner Avenue",
        parentOne: {
            name: "Tobin Uden",
            phoneNum: "990-368-9586",
            email: "tuden8@mediafire.com"
        },
        parentTwo: {
            name: "Tobin Uden",
            phoneNum: "742-977-6146",
            email: "tuden8@booking.com"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/etpariatureveniet.png?size=50x50&set=set1",
        stu_id: "01HZCH7",
        lastName: "Barrington",
        firstName: "Lanny",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Female",

        class: "11C",
        classCompletionStatus: false,
        address: "6050 Sutteridge Plaza",
        parentOne: {
            name: "Lanny Barrington",
            phoneNum: "104-530-3440",
            email: "lbarrington9@webeden.co.uk"
        },
        parentTwo: {
            name: "Lanny Barrington",
            phoneNum: "767-904-1910",
            email: "lbarrington9@angelfire.com"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/laborumperferendissed.png?size=50x50&set=set1",
        stu_id: "01H4CH7",
        lastName: "Bambrough",
        firstName: "Jeannie",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Female",

        class: "11C",
        classCompletionStatus: true,
        address: "80 Mitchell Lane",
        parentOne: {
            name: "Jeannie Bambrough",
            phoneNum: "978-182-7793",
            email: "jbambrougha@phoca.cz"
        },
        parentTwo: {
            name: "Jeannie Bambrough",
            phoneNum: "472-763-8782",
            email: "jbambrougha@prweb.com"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    },
    {
        photo: "https://robohash.org/laborumperferendissed.png?size=50x50&set=set1",
        stu_id: "0THZCH7",
        lastName: "Webland",
        firstName: "Janey",
        dateOfBirth: "4/29/2004",
        religion: "Christian",
        gender: "Female",

        class: "11A",
        classCompletionStatus: false,
        address: "98717 Beilfuss Center",
        parentOne: {
            name: "Janey Webland",
            phoneNum: "717-293-1593",
            email: "jweblandb@state.gov"
        },
        parentTwo: {
            name: "Janey Webland",
            phoneNum: "244-190-5998",
            email: "jweblandb@tamu.edu"
        },
        presence: [
            { date: '2023/09/01', presence: true },
            { date: '2023/09/02', presence: false },
            { date: '2023/09/03', presence: true },
            { date: '2023/09/04', presence: false },
            { date: '2023/09/05', presence: true },
            { date: '2023/09/06', presence: false },
            { date: '2023/09/07', presence: true },
            { date: '2023/09/08', presence: false },
            { date: '2023/09/09', presence: true },
            { date: '2023/09/10', presence: true },
            { date: '2023/09/11', presence: false },
            { date: '2023/09/12', presence: true },
            { date: '2023/09/13', presence: false },
            { date: '2023/09/14', presence: true },
            { date: '2023/09/15', presence: false },
            { date: '2023/09/16', presence: true },
            { date: '2023/09/17', presence: true },
            { date: '2023/09/18', presence: false },
            { date: '2023/09/19', presence: true },
            { date: '2023/09/20', presence: false },
            { date: '2023/09/21', presence: true },
            { date: '2023/09/22', presence: false },
            { date: '2023/09/23', presence: true },
            { date: '2023/09/24', presence: true },
            { date: '2023/09/25', presence: false },
            { date: '2023/09/26', presence: true },
            { date: '2023/09/27', presence: false },
            { date: '2023/09/28', presence: true },
            { date: '2023/09/29', presence: false },
            { date: '2023/09/30', presence: true },
            { date: '2023/10/01', presence: true },
            { date: '2023/10/02', presence: false },
            { date: '2023/10/03', presence: true },
            { date: '2023/10/04', presence: false },
            { date: '2023/10/05', presence: true },
            { date: '2023/10/06', presence: true },
            { date: '2023/10/07', presence: false },
            { date: '2023/10/08', presence: true },
            { date: '2023/10/09', presence: false },
            { date: '2023/10/10', presence: true },
            { date: '2023/10/11', presence: false },
            { date: '2023/10/12', presence: true },
            { date: '2023/10/13', presence: true },
            { date: '2023/10/14', presence: false },
            { date: '2023/10/15', presence: true },
            { date: '2023/10/16', presence: false },
            { date: '2023/10/17', presence: true },
            { date: '2023/10/18', presence: true },
            { date: '2023/10/19', presence: false },
            { date: '2023/10/20', presence: true },
            { date: '2023/10/21', presence: false },
            { date: '2023/10/22', presence: true },
            { date: '2023/10/23', presence: false },
            { date: '2023/10/24', presence: true },
            { date: '2023/10/25', presence: false },
            { date: '2023/10/26', presence: true },
            { date: '2023/10/27', presence: true },
            { date: '2023/10/28', presence: false },
            { date: '2023/10/29', presence: true },
            { date: '2023/10/30', presence: false },
            { date: '2023/10/31', presence: true },
            { date: '2023/11/01', presence: true },
            { date: '2023/11/02', presence: false },
            { date: '2023/11/03', presence: true },
            { date: '2023/11/04', presence: false },
            { date: '2023/11/05', presence: true },
            { date: '2023/11/06', presence: false },
            { date: '2023/11/07', presence: true },
            { date: '2023/11/08', presence: false },
            { date: '2023/11/09', presence: true },
            { date: '2023/11/10', presence: false },
            { date: '2023/11/11', presence: true },
            { date: '2023/11/12', presence: false },
            { date: '2023/11/13', presence: true },
            { date: '2023/11/14', presence: false },
            { date: '2023/11/15', presence: true },
            { date: '2023/11/16', presence: false },
            { date: '2023/11/17', presence: true },
            { date: '2023/11/18', presence: false },
            { date: '2023/11/19', presence: true },
            { date: '2023/11/20', presence: false },
            { date: '2023/11/21', presence: true },
            { date: '2023/11/22', presence: false },
            { date: '2023/11/23', presence: true },
            { date: '2023/11/24', presence: false },
            { date: '2023/11/25', presence: true },
            { date: '2023/11/26', presence: false },
            { date: '2023/11/27', presence: true },
            { date: '2023/11/28', presence: false },
            { date: '2023/11/29', presence: true },
            { date: '2023/11/30', presence: false },
            { date: '2023/12/01', presence: true },
            { date: '2023/12/02', presence: false },
            { date: '2023/12/03', presence: true },
            { date: '2023/12/04', presence: false },
            { date: '2023/12/05', presence: true },
            { date: '2023/12/06', presence: false },
            { date: '2023/12/07', presence: true },
            { date: '2023/12/08', presence: false },
            { date: '2023/12/09', presence: true },
            { date: '2023/12/10', presence: false },
            { date: '2023/12/11', presence: true },
            { date: '2023/12/12', presence: false },
            { date: '2023/12/13', presence: true },
            { date: '2023/12/14', presence: false },
            { date: '2023/12/15', presence: true },
            { date: '2023/12/16', presence: false },
            { date: '2023/12/17', presence: true },
            { date: '2023/12/18', presence: false },
            { date: '2023/12/19', presence: true },
            { date: '2023/12/20', presence: false },
            { date: '2023/12/21', presence: true },
            { date: '2023/12/22', presence: false },
            { date: '2023/12/23', presence: true },
            { date: '2023/12/24', presence: false },
            { date: '2023/12/25', presence: true },
            { date: '2023/12/26', presence: false },
            { date: '2023/12/27', presence: true },
            { date: '2023/12/28', presence: false },
            { date: '2023/12/29', presence: true },
            { date: '2023/12/30', presence: false },
            { date: '2023/12/31', presence: true },
            { date: '2024/01/01', presence: true },
            { date: '2024/01/02', presence: false },
            { date: '2024/01/03', presence: true },
            { date: '2024/01/04', presence: false },
            { date: '2024/01/05', presence: true },
            { date: '2024/01/06', presence: false },
            { date: '2024/01/07', presence: true },
            { date: '2024/01/08', presence: false },
            { date: '2024/01/09', presence: true },
            { date: '2024/01/10', presence: false },
            { date: '2024/01/11', presence: true },
            { date: '2024/01/12', presence: false },
            { date: '2024/01/13', presence: true },
            { date: '2024/01/14', presence: false },
            { date: '2024/01/15', presence: true },
            { date: '2024/01/16', presence: false },
            { date: '2024/01/17', presence: true },
            { date: '2024/01/18', presence: false },
            { date: '2024/01/19', presence: true },
            { date: '2024/01/20', presence: false },
            { date: '2024/01/21', presence: true },
            { date: '2024/01/22', presence: false },
            { date: '2024/01/23', presence: true },
            { date: '2024/01/24', presence: false },
            { date: '2024/01/25', presence: true },
            { date: '2024/01/26', presence: false },
            { date: '2024/01/27', presence: true },
            { date: '2024/01/28', presence: false },
            { date: '2024/01/29', presence: true },
            { date: '2024/01/30', presence: false },
            { date: '2024/01/31', presence: true },
            { date: '2024/02/01', presence: true },
            { date: '2024/02/02', presence: false },
            { date: '2024/02/03', presence: true },
            { date: '2024/02/04', presence: false },
            { date: '2024/02/05', presence: true },
            { date: '2024/02/06', presence: false },
            { date: '2024/02/07', presence: true },
            { date: '2024/02/08', presence: false },
            { date: '2024/02/09', presence: true },
            { date: '2024/02/10', presence: false },
            { date: '2024/02/11', presence: true },
            { date: '2024/02/12', presence: false },
            { date: '2024/02/13', presence: true },
            { date: '2024/02/14', presence: false },
            { date: '2024/02/15', presence: true },
            { date: '2024/02/16', presence: false },
            { date: '2024/02/17', presence: true },
            { date: '2024/02/18', presence: false },
            { date: '2024/02/19', presence: true },
            { date: '2024/02/20', presence: false },
            { date: '2024/02/21', presence: true },
            { date: '2024/02/22', presence: false },
            { date: '2024/02/23', presence: true },
            { date: '2024/02/24', presence: false },
            { date: '2024/02/25', presence: true },
            { date: '2024/02/26', presence: false },
            { date: '2024/02/27', presence: true },
            { date: '2024/02/28', presence: false }
        ],
        courses: [
            {
                name: 'History',
                grades: [
                    { maxGrade: 25, test: 18, date: '2023/09/10' },
                    { maxGrade: 28, test: 14, date: '2023/09/20' },
                    { maxGrade: 20, test: 19, date: '2023/10/10' },
                    { maxGrade: 24, test: 12, date: '2023/10/20' },
                    { maxGrade: 26, test: 17, date: '2023/11/10' },
                    { maxGrade: 23, test: 15, date: '2023/11/20' },
                    { maxGrade: 42, coursework: 16, date: '2023/10/10' },
                    { maxGrade: 29, exam: 25, date: '2023/11/28' },
                    { maxGrade: 27, exam: 28, date: '2023/11/30' }
                ]
            },
            {
                name: 'Geography',
                grades: [
                    { maxGrade: 22, test: 16, date: '2023/09/10' },
                    { maxGrade: 26, test: 11, date: '2023/09/30' },
                    { maxGrade: 19, test: 20, date: '2023/10/08' },
                    { maxGrade: 23, test: 13, date: '2023/10/26' },
                    { maxGrade: 21, test: 18, date: '2023/11/10' },
                    { maxGrade: 24, test: 15, date: '2023/11/20' },
                    { maxGrade: 35, coursework: 19, date: '2023/10/10' },
                    { maxGrade: 25, exam: 23, date: '2023/11/28' },
                    { maxGrade: 28, exam: 26, date: '2023/11/30' }
                ]
            },
            {
                name: 'Mathematics',
                grades: [
                    { maxGrade: 30, test: 22, date: '2023/09/15' },
                    { maxGrade: 27, test: 19, date: '2023/09/25' },
                    { maxGrade: 25, test: 23, date: '2023/10/15' },
                    { maxGrade: 26, test: 20, date: '2023/10/25' },
                    { maxGrade: 24, test: 18, date: '2023/11/15' },
                    { maxGrade: 28, test: 24, date: '2023/11/25' },
                    { maxGrade: 40, coursework: 30, date: '2023/10/15' },
                    { maxGrade: 29, exam: 27, date: '2023/11/29' },
                    { maxGrade: 30, exam: 30, date: '2023/12/01' }
                ]
            },
            {
                name: 'English',
                grades: [
                    { maxGrade: 24, test: 20, date: '2023/09/12' },
                    { maxGrade: 26, test: 17, date: '2023/09/22' },
                    { maxGrade: 22, test: 21, date: '2023/10/12' },
                    { maxGrade: 25, test: 18, date: '2023/10/22' },
                    { maxGrade: 23, test: 19, date: '2023/11/12' },
                    { maxGrade: 27, test: 22, date: '2023/11/22' },
                    { maxGrade: 38, coursework: 25, date: '2023/10/12' },
                    { maxGrade: 28, exam: 24, date: '2023/11/30' },
                    { maxGrade: 29, exam: 26, date: '2023/12/02' }
                ]
            }
        ]
    }
    ];
