import React from 'react'
import './Header.css'
import userIcon from '../../icons/userIcon.svg';




function Header({ menuName }) {
    const headerContents = {
        searchBarPlaceholder: 'search',
        userInfo: {
            greetingMessage: 'Hello, ',
            userFirstName: 'userFirstName',
            profileImage: userIcon,
        },

    }
    return (
        <nav className='nav-bar'>

            <div className='header-items'>
                <h3>
                    {menuName}
                </h3>

                <input placeholder={headerContents.searchBarPlaceholder} type='text' id='header-search'>
                </input>

                <div className='user-info-sec'>
                    <div className='user-info'>
                        <p>
                            {headerContents.userInfo.greetingMessage}
                        </p>

                        <h6>{headerContents.userInfo.userFirstName}</h6>

                        <img src={`${headerContents.userInfo.profileImage}`} alt='user profile'>
                        </img>
                    </div>
                </div>

            </div>

        </nav>
    )
}

export default Header