import './App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import Admin from './Admin/Admin';
import Dashboard from './reusableComps/Dashboard/Dashboard';
import AdminStudentSec from './reusableComps/AdminStudentSec/AdminStudentSec.js';
import AdminTeacherSec from './reusableComps/AdminTeacherSec/AdminTeacherSec.js';
import Events from './reusableComps/Events/Events.js';
import Settings from './reusableComps/Settings/Settings.js';
import Login from './reusableComps/Login/Login.js';
import Home from './Home/Home.js';
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route index element={<Home />} />
      <Route path='login' element={<Login />} />
      <Route path='Admin' element={<Admin />}>
        <Route index element={<Dashboard />}></Route>
        <Route path='Students' element={<AdminStudentSec />}></Route>
        <Route path='Teachers' element={<AdminTeacherSec />}></Route>
        <Route path='Events' element={<Events />}></Route>
        <Route path='Settings' element={<Settings />}></Route>
      </Route>
    </Route>
  )
)

function App() {

  return (
    <RouterProvider router={router} />
  );
}

export default App;
