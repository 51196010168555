import { useContext, useState } from 'react';
import { StudentContext, PopUpEditAdd, PopUpExport } from '../../../Admin/AdminImports';
import './AdminStudentAddExport.css';


function AdminStudentAddExport({ popUpEditAdd }) {
    const { toExportVal, handlePopUpEditAdd } = useContext(StudentContext);
    const [newStudent, setNewStudent] = useState({
        photo: "",
        lastName: "",
        firstName: "",
        dateOfBirth: "",
        religion: "",
        gender: "",
        class: "",
        classCompletionStatus: false,
        address: "",
        parentOne: { name: "", phoneNum: "", email: "" },
        parentTwo: { name: "", phoneNum: "", email: "" }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
    };
    const handleNewStudentField = (fieldName, e) => {
        const updatePreview = { ...newStudent };

        const subFieldsChange = fieldName.split('-');
        const parentField = subFieldsChange[1];
        const childField = subFieldsChange[0];

        if (subFieldsChange.length > 1) {
            updatePreview[parentField][childField] = e.target.value;
        } else {
            updatePreview[fieldName] = e.target.value;
        }
        setNewStudent(updatePreview);
    };
    const selectedNum = toExportVal.length;
    return (
        <div className="AdminStudentAddExport">
            <div
                className="Add-btn"
                onClick={() => handlePopUpEditAdd('adminStudentAddExport', 'Create')}
                data-testid={`AdminStudentAddExport-PopUpEdiAddbtn`}
            >
                Add
            </div>

            <div className="ExportAll-btn"
                onClick={() => handlePopUpEditAdd('adminStudentAddExport', 'Export')}
                data-testid={`AdminStudentAddExport-PopUpExport`}
            >
                Export {selectedNum === 0 ? 'All' : `(${selectedNum})`}
            </div>
            <PopUpEditAdd
                data={newStudent}
                handleSubmit={handleSubmit}
                handleFields={handleNewStudentField}
                name={'Create'}
                component={'adminStudentAddExport'}
                popUpEditAdd={popUpEditAdd}
                handlePopUpEditAdd={handlePopUpEditAdd}
            />
            <PopUpExport
                name={'Export'}
                component={'adminStudentAddExport'}
                popUpEditAdd={popUpEditAdd}
                handlePopUpEditAdd={handlePopUpEditAdd}
                toExportVal={toExportVal}
            />
        </div>
    );
}

export default AdminStudentAddExport;