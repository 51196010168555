import './SideBar.css'
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

function SideBar({ menu, handleMenu }) {
    return (
        <nav className="SideBar">
            <div className='sideBarLinks'>
                {
                    menu.map(
                        (option, i) =>
                            <NavLink
                                to={option['link']}
                                className={option['name']!=='Logout'?'link':'link logout'}
                                data-testid="options-link"
                                key={i.toString()}
                                onClick={() => handleMenu(option['name'])}
                            >
                                <img src={option['icon']} alt={`${option['name']} icon`}></img>
                                <span data-testid={`option-${option['name']}`}>{option['name']}</span>
                            </NavLink>

                    )
                }
            </div>
           
        </nav>
    );
}

SideBar.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    handleMenu: PropTypes.func.isRequired,
};

export default SideBar;