import React from 'react';
import './TopWidgets.css'


function TopWidgets({ widgets }) {

    return (
        <div className='widget-container'>
            {
                widgets.map(
                    (item,i) =>
                        <div className='widget' key={i.toString()}>
                            <div className='widget-icon'>
                                <img src={item['icon']} alt='widget icon' />
                            </div>
                            <div className='widget-text'>
                                <p>
                                    {item['name']}
                                </p>
                                <h2>
                                    {item['totalNumber']}
                                </h2>
                            </div>

                        </div>
                )
            }
        </div>
    )
}

export default TopWidgets