import React, { useContext, useState } from 'react';
import './CalendarMini.css';
import {
    getMonthName,getDaysInMonth, CalendarContext,CreateEvent
} from "../../../Admin/AdminImports";

const CalendarMini = () => {
    const { month, year, setSelectedDay } = useContext(CalendarContext);
    const daysInMonth = getDaysInMonth(year, month);
    const monthName = getMonthName(month);
    const [showCreateEvent, setShowCreateEvent] = useState(false);


    return (
        <div className="CalendarMini" data-testid={`calendar-mini`}>
            <div className="CalendarMini-Header">
                <span className="CalendarMini-Month" data-testid={`calendar-month`}>{monthName}</span>
                <span alt="Add"
                    className="CalendarMini-AddIcon"
                    onClick={() => setShowCreateEvent(true)}
                    data-testid={`add-event-icon`}>+</span>
                <CreateEvent show={showCreateEvent} onClose={() => setShowCreateEvent(false)} />
            </div>
            <div className="CalendarMini-Grid" data-testid={`calendar-grid`}>
                {daysInMonth.map((day, index) => (
                    <div
                        key={index.toString()}
                        className={`CalendarMini-Day ${day.isToday ? 'is-today' : ''} ${day.isSelected ? 'is-selected' : ''}`}
                        onClick={() => setSelectedDay(day.dateString)}
                        data-testid={`day-${index + 1}`}
                    >
                        {day.day}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CalendarMini;
