import './AdminAcademicList.css';
import PropTypes from 'prop-types';
function AdminAcademicList({ columnNames, staffList, title }) {
    const commonStyling = {
        borderBottom: '2px solid #f2f2f2'
    }
    return (
        <div className="AdminAcademicList">
            <span className='title'>{title}</span>
            <table>
                <thead>
                    <tr>
                        {
                            columnNames.map((columnName, i) => <th
                                key={i.toString()}
                                className={columnName === "Attendance" || columnName === "Students" ?
                                    'statsColumn' : columnName === 'Name' ? 'nameColumn' : ''}
                            >{columnName}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        staffList.map((staff, i) =>
                            <tr key={i.toString()}>
                                <td className='names' style={i !== staffList.length - 1 ?
                                    commonStyling : {}}>{staff['name']}</td>
                                <td
                                    data-testid={`${staff['name']}-${staff['discipline']}`}
                                    style={i !== staffList.length - 1 ?
                                        commonStyling : {}}
                                >{staff['discipline']}</td>
                                <td
                                    data-testid={`${staff['name']}-${staff['attendance']}`}
                                    className="stats" style={i !== staffList.length - 1 ?
                                        commonStyling : {}}
                                >{staff['attendance']}%</td>
                                <td
                                    data-testid={`${staff['name']}-${staff['students']}`}
                                    className="stats" style={i !== staffList.length - 1 ?
                                        commonStyling : {}}
                                >{staff['students']}</td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}
AdminAcademicList.propTypes = {
    columnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    staffList: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            discipline: PropTypes.string.isRequired,
            attendance: PropTypes.number.isRequired,
            students: PropTypes.number.isRequired,
        })
    ).isRequired,
    title: PropTypes.string.isRequired,
};
export default AdminAcademicList;