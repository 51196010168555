import './Dashboard.css';
import {
    Header, CalendarEvents, AdminAcademicList,
    TopWidgets, AdminAttendanceFlowChart1, staffList, columnNames, data
} from "../../Admin/AdminImports.js";

import { useEffect, useState } from "react";
import widgetStudentIcon from '../../icons/widgetStudentsIcon.svg';
import widgetStaffIcon from '../../icons/widgetStaffIcon.svg';
import widgetChartIcon from '../../icons/widgetChartIcon.svg';



const widgets = [
    {
        icon: widgetStudentIcon,
        name: 'Students',
        totalNumber: '350',
    },

    {
        icon: widgetStaffIcon,
        name: 'Staff',
        totalNumber: '75',
    },

    {
        icon: widgetChartIcon,
        name: 'Budget',
        totalNumber: '$75000',
    },
]

const monthsOfAttendancesAvailable = (data, year) => {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    let months = [];

    data[0]['presence'].forEach(
        ({ date }) => {
            const dateFormat = new Date(date);
            const month = monthNames[dateFormat.getMonth()];
            if (!months.includes(month) && year === dateFormat.getFullYear()) months.push(month);
        }
    );

    return months;
};
const yearsOfAttendancesAvailable = (data) => {
    let years = [];

    data[0]['presence'].forEach(
        ({ date }) => {
            const dateFormat = new Date(date);
            const year = dateFormat.getFullYear();
            if (!years.includes(year)) years.push(year);
        }
    );

    return years;
};


function Dashboard() {
    // Get the months of the current academic year based on the student list and the current year
    const currentAcademicYearMonths = monthsOfAttendancesAvailable(data, new Date().getFullYear());

    // Get the current month of attendance by selecting the last month from the current academic year months
    const currentMonthOfAttendance = currentAcademicYearMonths[currentAcademicYearMonths.length - 1];

    // Set the initial state for the time frame chart using the current month and year
    const [timeFrameChart, setTimeFrameChart] = useState({
        month: currentMonthOfAttendance,
        year: new Date().getFullYear()
    });

    // Set the initial state for the available time frames, including months and years
    const [timeFramesAvailable, setTimeFrameAvailable] = useState({
        months: [...monthsOfAttendancesAvailable(data, timeFrameChart['year'])],
        years: [...yearsOfAttendancesAvailable(data)]
    });

    // Update the available months when the time frame chart changes
    useEffect(() => {
        setTimeFrameAvailable(t => ({
            ...t,
            months: [...monthsOfAttendancesAvailable(data, timeFrameChart['year'])]
        }));
    }, [timeFrameChart]);

    // Update the time frame chart when a new time frame is selected
    const handleTimeFrameChart = (timeFrame) => {
        if (timeFrame['year'] !== timeFrameChart['year']) {
            const monthsInSelectedYear = monthsOfAttendancesAvailable(data, timeFrame['year']);
            const firstMonthOfSelectedYear = monthsInSelectedYear[0];
            setTimeFrameChart({ month: firstMonthOfSelectedYear, year: timeFrame['year'] });
        } else setTimeFrameChart({ ...timeFrame })
    };
    return (
        <div
            className="Dashboard"
            data-testid="Dashboard"
        >
            <Header menuName={"Dashboard"} />
            <div className="dashboard-container">
                <div className="leftSideDashboard">

                    <AdminAttendanceFlowChart1
                        title={"Students' Attendance"}
                        width={640}
                        height={200}
                        studentList={data}
                        handleTimeFrameChart={handleTimeFrameChart}
                        timeFrameChart={timeFrameChart}
                        timeFramesAvailable={timeFramesAvailable}
                    />
                    <AdminAcademicList
                        title={'Academic Staff List'}
                        staffList={staffList}
                        columnNames={columnNames} />
                </div>
                <div className='rigthSideDashboard'>
                    <TopWidgets widgets={widgets} />
                    <CalendarEvents />
                </div>
            </div>

        </div>
    );
}

export default Dashboard;