import { useState, useRef } from 'react';
import { closeIcon } from '../../assetsImports'
import { fieldNameFormat } from '../useFullFunctions/useFullFunc';
import './PopUpEditAdd.css';

const Field = ({ fieldName, value, handleFields, subFieldParent }) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    const isDate = dateRegex.test(value);
    return (
        <div className={'field'} >
            <label htmlFor={fieldName}>
                {fieldNameFormat(fieldName)}
            </label>
            <input
                id={fieldName}
                type={isDate ? 'date' : 'text'}
                name={fieldName}
                value={value}
                onChange={(e) => {
                    if (subFieldParent) handleFields(`${fieldName}-${subFieldParent}`, e)
                    else handleFields(fieldName, e);
                }}
            >
            </input>
        </div>);
}
const FieldWithSubFields = ({ field, fieldName, handleFields }) => {
    let fieldKeys = [];
    const isFieldArray = Array.isArray(field) ? true : false;
    if (!isFieldArray) fieldKeys = Object.keys(field);

    return !isFieldArray ?
        <div className='SubFields'>
            <span>{fieldNameFormat(fieldName)}</span>
            <div className='fields'>
                {
                    fieldKeys.map((keyName) => < Field
                        key={keyName}
                        fieldName={keyName}
                        value={field[keyName]}
                        handleFields={handleFields}
                        subFieldParent={fieldName}
                    />)
                }
            </div>
        </div> : null

};
const UploadProfilePicture = ({ field }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        setSelectedFile(event.target.files[0]);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewImage(null);
        }
    };
    const handleRemoveImage = () => {
        setSelectedFile(null);
        setPreviewImage(null);
        fileInputRef.current.value = "";
    }

    return (
        <div
            className='UploadProfilePicture'
        >
            <img
                className="closingBtn"
                style={selectedFile ? {} : { display: 'none' }}
                src={closeIcon}
                alt="closing icon"
                onClick={() => handleRemoveImage()}
            ></img>
            <label htmlFor="imageInput">

                {
                    selectedFile ?
                        <img
                            className="imgPrev"
                            src={previewImage}
                            alt="Selected"
                        />
                        : field ?
                            <img
                                className="imgPrev"
                                src={field}
                                alt="Selected"
                            /> :
                            <span >Click to add an image</span>
                }

                <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
            </label>

        </div>
    );
};
const TrueFalseBtn = ({ fieldName, field }) => {
    const [clicked, setClicked] = useState(field);
    const handleClick = () => setClicked(!clicked);
    return <div className='booleanContainer'>
        <span>{fieldNameFormat(fieldName)}</span>
        <div
            className='TrueFalseBtn'
            style={clicked ? { backgroundColor: 'green' } : { backgroundColor: 'red' }}>
            <div className='Btn'
                onClick={() => handleClick()}
                style={clicked ? { right: '2px' } : { left: '2px' }}></div>
        </div>
    </div>
}


function PopUpEditAdd({
    data,
    handleSubmit,
    handleFields,
    popUpEditAdd,
    handlePopUpEditAdd,
    name,
    component
}) {
    const dataKeyList = Object.keys(data);
    const popUpView = popUpEditAdd.filter((popUp) => popUp['name'] === name && popUp['component'] === component)[0];

    return (

        popUpView['status'] ? <>
            <form
                className="PopUpEditAdd"
                onSubmit={handleSubmit}
                style={popUpView['status'] ? { display: 'flex' } : { display: 'none' }}
                data-testid={`popUpEdit-${component}`}
            >

                <div className='upperSec'>
                    <h4>General information</h4>
                    <img src={closeIcon} alt='close icon' data-testid={`popUpEdit-close-icon-${component}`} onClick={() => handlePopUpEditAdd(component, name)}></img>
                </div>
                <div className='fields-container' >
                    {
                        dataKeyList.map((keyName) => {
                            const isPhotoField = keyName === 'photo';
                            if (isPhotoField) {
                                return <UploadProfilePicture
                                    key={keyName}
                                    field={data[keyName]}
                                />;
                            } else return '';
                        })
                    }
                    {
                        dataKeyList.map((keyName) => {
                            if (typeof data[keyName] === 'object') return (
                                <FieldWithSubFields
                                    key={keyName}
                                    fieldName={keyName}
                                    field={data[keyName]}
                                    handleFields={handleFields} />
                            );
                            else return '';
                        })
                    }
                    <div className='date-text-fields'>
                        {
                            dataKeyList.map((keyName) => {

                                if (typeof data[keyName] !== 'object') {

                                    const isPhotoField = keyName === 'photo';
                                    const isBooleanField = typeof data[keyName] === 'boolean';
                                    if (!isPhotoField) {
                                        if (isBooleanField)
                                            return <TrueFalseBtn
                                                key={keyName}
                                                fieldName={keyName}
                                                field={data[keyName]} />;
                                        else if (keyName !== 'stu_id') {
                                            return < Field
                                                key={keyName}
                                                fieldName={keyName}
                                                value={data[keyName]}
                                                handleFields={handleFields}
                                            />
                                        }
                                    }
                                }
                                return '';
                            })
                        }
                    </div>


                    <button >{popUpView['name']}</button>
                </div>
            </form>
        </> : <></>
    );
}

export default PopUpEditAdd;