import { useEffect, useState } from 'react';
import { closeIcon } from '../../../assetsImports';
import { CSVLink } from 'react-csv';
import './PopUpExport.css';

const todaysDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');

    const todayDate = `${year}-${month}-${day}`;

    return todayDate;
}

function PopUpExport({
    name,
    component,
    popUpEditAdd,
    handlePopUpEditAdd,
    toExportVal,
}) {
    const popUpView = popUpEditAdd.filter((popUp) => popUp['name'] === name && popUp['component'] === component)[0];
    const [csv, setCsv] = useState('');
    
    const handleCsv = (studentList) => {
        let csvFormat = 'stu_id,lastName,firstName,dateOfBirth,religion,gender,class,classCompletionStatus,address\n';
        studentList.forEach((student) => {
            const buffer = `${student.stu_id},${student.lastName},${student.firstName},${student.dateOfBirth},${student.religion},${student.gender},${student.stu_class},${student.classCompletionStatus},${student.address}\n`;
            csvFormat += buffer;
        });
        setCsv(csvFormat);
    };

    useEffect(() => {
        handleCsv(toExportVal);
    }, [toExportVal]);
    return (
        popUpView['status'] ? <>
            <div className="PopUpExport"
                style={popUpView['status'] ? { display: 'flex' } : { display: 'none' }}
                data-testid={`popUpExport-${component}`}
            >
                <div className='upperSec'>
                    <h4>Method of export</h4>
                    <div className='closeBtn'>
                        <img src={closeIcon} data-testid={`popUpExport-close-icon-${component}`} alt='close icon' onClick={() => handlePopUpEditAdd(component, name)}></img>
                    </div>
                </div>
                <div className='studentSelected'>
                    <table>
                        <thead>
                            <tr>
                                <th>photo</th>
                                <th>Name</th>
                                <th>Class</th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                toExportVal.map((student, index) =>
                                    <tr key={index} >
                                        <td><img src={student['photo']} alt='student '></img></td>
                                        <td>{student['firstName']}</td>
                                        <td>{student['class']}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                </div>

                <div className='methods'>
                    <CSVLink data={csv} filename={`${'shoolName'}${todaysDate()}`}><button className='Csv' >Csv</button></CSVLink>
                    <button className='Xls'>Xls</button>
                    <button className='Pdf'>Pdf</button>
                </div>

            </div>
        </> : <></>
    );
}

export default PopUpExport;