import { useContext } from 'react';
import './EventList.css';
import {
    ViewEvent, EventContext
} from "../../../Admin/AdminImports";


export const EventItem = ({ event }) => {
    return (
        <div className="EventItem-Container" data-testid={`event-items-${event.id}`}>
            <div className={`EventItem-Date`}>
                {event.date}
            </div>

            <div className={`EventItems`}>
                <div className="EventItem-Time">
                    {event.startTime} <span className="EventItem-Duration">{(event.endTime)}</span>
                </div>
                <div className={`EventItem-Divider`}></div>
                <div className="EventItem-Details">
                    <div className="EventItem-Title" data-testid={`event-title-${event.id}`}>{event.title}</div>
                    <a href={event.link} className="EventItem-Link">{event.location}</a>
                </div>
            </div>
        </div>
    );
};



export const EventList = () => {

    const { events, selectedEvent, handleViewEvent, handleCloseEvent } = useContext(EventContext);

    return (
        <div className="EventList">

            {events.map(eventItem => (
                <div
                    key={eventItem.id}
                    onClick={() => handleViewEvent(eventItem)} >
                    <EventItem event={eventItem} />
                </div>
            ))}
            {selectedEvent && <ViewEvent event={selectedEvent} onClose={handleCloseEvent} />}
        </div>
    );
};
