import './Event.css';
import {
    CalendarMini, Calendar, CalendarNavigation, CalendarGroup, todayDate, event, EventList, CalendarContext, EventContext
} from "../../Admin/AdminImports.js";
import { useEffect, useState } from "react";

function Events() {
    //------------------------------------------
    //CalendarNavigation & Calendar comon states.
    //-------------------------------------------
    const today = todayDate();
    const [month, setMonth] = useState(today.month + 1);
    const [year, setYear] = useState(today.year);
    const [selectedDay, setSelectedDay] = useState(null);
    const [events, setEvents] = useState([...event]);

    const handleYear = (action, quickJump) => {
        let newYear = year;
        if (!quickJump) {
            newYear += action;
        } else newYear = quickJump;
        setYear(newYear);
    }

    const handleMonth = (action, quickJump) => {
        let newMonth = month;
        if (!quickJump) {
            if (action > 0)
                if (newMonth + action > 12) {
                    newMonth = 1 + (newMonth + action - 12);
                    handleYear(1);
                }
                else newMonth += 1;
            else
                if (newMonth + action < 1) {
                    newMonth = 12 - (newMonth + action);
                    handleYear(-1);
                }
                else newMonth -= 1;
        } else newMonth = quickJump;
        setMonth(newMonth);
    };

    const handleAddEvent = (newEvent) => {
        setEvents(prevEvents => [...prevEvents, newEvent]);
    };


    const [group, setGroup] = useState('personal');

    const handleChangeGroup = (newGroup) => {
        setGroup(newGroup);
    };

    useEffect(() => {
        const filteredEvents = () => {
            if (group === 'personal')
                setEvents(() => event.filter(event => event.people.length === 1 && event.people[0] === 'xyz@xyz.com'));
            else setEvents([...event])
        }
        filteredEvents();
    }, [group]);

 
    const [selectedEvent, setSelectedEvent] = useState(null);

    const handleViewEvent = (event) => {
        setSelectedEvent(event);
    }

    const handleCloseEvent = () => {
        setSelectedEvent(null);
    }

    return (
        <div className={`Events-Container`} data-testid="Events">
            <CalendarContext.Provider value={{ month, year, selectedDay, setSelectedDay, handleMonth, handleYear }}>
                <EventContext.Provider value={{ events, handleAddEvent, selectedEvent, setSelectedEvent, handleViewEvent, handleCloseEvent }}>
                    <div className={`Events-Calendar`}>
                        <CalendarNavigation />
                        <Calendar />
                    </div>
                    <div className={`Events-Content`}>
                        <CalendarMini />
                        <CalendarGroup group={group} handleChangeGroup={handleChangeGroup} />
                        <EventList />
                    </div>
                </EventContext.Provider>
            </CalendarContext.Provider>
        </div>
    );
}

export default Events;